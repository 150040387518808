enifed('ember-template-compiler/plugins/assert-input-helper-without-block', ['exports', 'ember-debug', 'ember-template-compiler/system/calculate-location-display'], function (exports, _emberDebug, _calculateLocationDisplay) {
  'use strict';

  exports.default = function (env) {
    var moduleName = env.meta.moduleName;

    return {
      name: 'assert-input-helper-without-block',

      visitors: {
        BlockStatement: function (node) {
          if (node.path.original !== 'input') {
            return;
          }

          true && !false && (0, _emberDebug.assert)(assertMessage(moduleName, node));
        }
      }
    };
  };


  function assertMessage(moduleName, node) {
    var sourceInformation = (0, _calculateLocationDisplay.default)(moduleName, node.loc);

    return 'The {{input}} helper cannot be used in block form. ' + sourceInformation;
  }
});