enifed('ember-template-compiler/system/precompile', ['exports', 'ember-template-compiler/system/compile-options', 'require'], function (exports, _compileOptions, _require2) {
  'use strict';

  exports.default =

  /**
    Uses HTMLBars `compile` function to process a string into a compiled template string.
    The returned string must be passed through `Ember.HTMLBars.template`.
  
    This is not present in production builds.
  
    @private
    @method precompile
    @param {String} templateString This is the string to be compiled by HTMLBars.
  */
  function (templateString, options) {
    if (!glimmerPrecompile && (0, _require2.has)('@glimmer/compiler')) {
      glimmerPrecompile = (0, _require2.default)('@glimmer/compiler').precompile;
    }

    if (!glimmerPrecompile) {
      throw new Error('Cannot call `compile` without the template compiler loaded. Please load `ember-template-compiler.js` prior to calling `compile`.');
    }

    return glimmerPrecompile(templateString, (0, _compileOptions.default)(options));
  };
  /**
  @module ember
  */

  var glimmerPrecompile = void 0;
});