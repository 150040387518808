enifed('ember-template-compiler/plugins/assert-reserved-named-arguments', ['exports', 'ember-debug', 'ember-template-compiler/system/calculate-location-display'], function (exports, _emberDebug, _calculateLocationDisplay) {
  'use strict';

  exports.default = function (env) {
    var moduleName = env.meta.moduleName;

    return {
      name: 'assert-reserved-named-arguments',

      visitors: {
        PathExpression: function (node) {
          if (node.original[0] === '@') {
            true && !false && (0, _emberDebug.assert)(assertMessage(moduleName, node));
          }
        }
      }
    };
  };


  function assertMessage(moduleName, node) {
    var path = node.original;
    var source = (0, _calculateLocationDisplay.default)(moduleName, node.loc);

    return '\'' + path + '\' is not a valid path. ' + source;
  }
});