enifed('ember-template-compiler/plugins/deprecate-render', ['exports', 'ember-debug', 'ember-template-compiler/system/calculate-location-display'], function (exports, _emberDebug, _calculateLocationDisplay) {
  'use strict';

  exports.default = function (env) {
    var moduleName = env.meta.moduleName;

    return {
      name: 'deprecate-render',

      visitors: {
        MustacheStatement: function (node) {
          if (node.path.original !== 'render') {
            return;
          }
          if (node.params.length !== 1) {
            return;
          }

          each(node.params, function (param) {
            if (param.type !== 'StringLiteral') {
              return;
            }

            true && !false && (0, _emberDebug.deprecate)(deprecationMessage(moduleName, node), false, {
              id: 'ember-template-compiler.deprecate-render',
              until: '3.0.0',
              url: 'https://emberjs.com/deprecations/v2.x#toc_code-render-code-helper'
            });
          });
        }
      }
    };
  };


  function each(list, callback) {
    var i, l;

    for (i = 0, l = list.length; i < l; i++) {
      callback(list[i]);
    }
  }

  function deprecationMessage(moduleName, node) {
    var sourceInformation = (0, _calculateLocationDisplay.default)(moduleName, node.loc);
    var componentName = node.params[0].original;


    return 'Please refactor `' + ('{{render "' + componentName + '"}}') + '` to a component and invoke via' + (' `' + ('{{' + componentName + '}}') + '`. ' + sourceInformation);
  }
});