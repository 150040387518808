/**
 * Expweb Site Analytics
 *
 * THIS FILE IS GENERATED AUTOMATICALLY
 *
 * Please follow the instructions at site-analytics/README.md to change it.
 *
 * Last build: Thu May 14 2015 17:48:22 GMT+0200 (Central Europe Daylight Time)
 */

/* eslint-env browser */
/* global s_gi, dctk */

//Exclude Safari preview window
if (navigator.userAgent.match(/4 Public Beta Safari/)|| navigator.loadPurpose === "preview"){
  s_exp_account = "devnull";
  window.suppressInlineOmniture = true;
}

// set s_exp_* values to legacy settings if needed
if(! window.s_exp_account) {	var s_exp_account = window.s_account; }
if(! window.s_exp_EAPID) { var s_exp_EAPID = window.s_EAPID; }
if(! window.s_exp_BrandEAPID) { var s_exp_BrandEAPID = window.s_BrandEAPID; }

var hostname = window.location.hostname;

s_exp_account = determineAccount();

var s_exp = s_gi(s_exp_account);

s_exp.trackExternalLinks = false;
s_exp.linkInternalFilters = document.domain;
s_exp.linkLeaveQueryString = false;
s_exp.trackDownloadLinks = true;
s_exp.linkDownloadFileTypes = "exe,zip,wav,mp3,mov,mpg,avi,wmv,doc,pdf,xls";
s_exp.trackInlineStats = false;
s_exp.linkTrackVars = "None";
s_exp.linkTrackEvents = "None";
s_exp.charSet = window.s_charSet || "ISO-8859-1";
//s_exp.prop50 = "siteAnalytics + AppMeasurement " + s_exp.version;

s_exp.trackingServer="om.expedia.com";
s_exp.trackingServerSecure="oms.expedia.com";



function determineAccount()
{
  var account = 'expediaglobalsupply';
  if(hostname.match('karmalab') || location.protocol === "file:" || hostname.match('localhost')) {
    account = 'expediaglobalsupplydev';
  }


  return account;
}

function eVar60FormatDate(){
  // date formating
  var currentDate, ampm, weekDays, currentHour, currentMinutes;
  currentDate = new Date();
  weekDays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  currentHour = currentDate.getHours();
  if (currentHour > 12){currentHour = currentHour - 12; ampm = "PM";}
  else {ampm = "AM";}
  currentMinutes = currentDate.getMinutes();
  if (currentMinutes < 10){currentMinutes = "0" + currentMinutes;}
  return weekDays[currentDate.getDay()] + "|" + currentHour + ":" + currentMinutes + ampm;
}
s_exp.eVar60 = eVar60FormatDate();


// Begin Global Link Tracking Code
/* get track object from s_exp in case dctk is in the enviroment (this is an edge case) */
function getTrackVarsFroms_exp(s_exp){
  var tmpTrackVars = {};
  for (var key in s_exp){
    if (s_exp.hasOwnProperty(key)){
      if ((/^eVar\d+|prop\d+/g).test(key)) {
        tmpTrackVars[key] = s_exp[key];
      }
    }
  }
  return tmpTrackVars;
}

/* Link Tracking Functions */
function s_exp_trackClick(linkObj,linkType,linkID,regionID,deprtDate,arrivDate,amenityVal,srchDetails,traffic17,traffic18,clickedPosition,traffic19,traffic20,traffic29) {
  if(linkType=="i"){
    // save in cookie for next page (future feature)
  } else if(linkType=="e") {
    s_exp.trackExternalLinks=false; // used to avoid doublecounting
    s_exp.linkTrackVars='prop16,eVar28,eVar61,prop75';
    s_exp.linkTrackEvents='None';
    s_exp.prop16=linkID;
    s_exp.eVar28=linkID;
    if ((typeof dctk !== 'undefined') && (typeof dctk.trackExit !== 'undefined')){
      dctk.trackExit(linkID, linkObj, getTrackVarsFroms_exp(s_exp));
    } else {
      s_exp.tl(linkObj,'e','RFRR Exit Link');
    }

  } else if(linkType=="d") {
    s_exp.trackDownloadLinks=false;
    s_exp.linkTrackVars = 'prop11,prop12,prop16,eVar18,eVar28,eVar34';
    s_exp.prop16 = linkID;
    s_exp.eVar28 = linkID;
    s_exp.tl(true,'d','INS:TermsLink Downloads');
  } else if(linkType=="a") {
    s_exp.linkTrackVars='prop12,prop16,eVar28,eVar61,prop75';
    s_exp.linkTrackEvents='None';
    s_exp.prop16=linkID;
    s_exp.eVar28=linkID;
    if (regionID) {
      s_exp.linkTrackVars += ',prop1';
      s_exp.prop1=regionID;
    }
    if (deprtDate) {
      s_exp.linkTrackVars += ',prop2';
      s_exp.prop2=deprtDate;
    }
    if (arrivDate) {
      s_exp.linkTrackVars += ',prop3';
      s_exp.prop3=arrivDate;
    }
    if (amenityVal) {
      s_exp.linkTrackVars += ',prop4';
      s_exp.prop4=amenityVal;
    }
    if ((typeof dctk !== 'undefined') && (typeof dctk.trackAction !== 'undefined')){
      dctk.trackAction(linkID, linkObj, getTrackVarsFroms_exp(s_exp));
    } else {
      s_exp.tl(true,'o','RFRR Action Link');
    }
  } else if(linkType=="errors") {
    s_exp.trackDownloadLinks=false;
    s_exp.linkTrackVars='prop12,eVar61,prop75,prop36';
    s_exp.linkTrackEvents='None';
    s_exp.prop36 = linkID;
    if ((typeof dctk !== 'undefined') && (typeof dctk.trackAction !== 'undefined')){
      dctk.trackAction(linkID, linkObj, getTrackVarsFroms_exp(s_exp));
    } else {
      s_exp.tl(true,'o','RFRR Action Link');
    }
  } else if(linkType=="amenityCBClick") {
    s_exp.linkTrackVars='eVar45,eVar61';
    s_exp.linkTrackEvents='None';
    s_exp.eVar45=linkID;
    if ((typeof dctk !== 'undefined') && (typeof dctk.trackAction !== 'undefined')){
      dctk.trackEvent('o', 'Amenities Search', getTrackVarsFroms_exp(s_exp));
    } else {
      s_exp.tl(true,'o','Amenities Search');
    }
  } else if(linkType=="refineSearch") {
    s_exp.linkTrackVars='prop2,eVar2,prop4,eVar4,prop5,eVar5,prop6,eVar6,prop16,eVar28,eVar45,eVar47,prop17,prop18,prop19,prop20,prop29,eVar34,prop34,eVar61,prop75';
    s_exp.linkTrackEvents='None';
    s_exp.prop16=linkID;
    s_exp.eVar28=linkID;
    s_exp.prop2='hotels';
    s_exp.eVar2="D=c2";
    s_exp.prop4=regionID;
    s_exp.eVar4="D=c4";
    s_exp.prop5=arrivDate;
    s_exp.prop6=deprtDate;
    s_exp.eVar45=amenityVal;
    s_exp.eVar47=srchDetails;
    s_exp.prop17=traffic17;
    s_exp.prop18=traffic18;
    s_exp.prop19=traffic19;
    s_exp.prop20=traffic20;
    s_exp.prop29=traffic29;
    if ((typeof dctk !== 'undefined') && (typeof dctk.trackAction !== 'undefined')){
      dctk.trackEvent('o', 'Refine Search Link', getTrackVarsFroms_exp(s_exp));
    } else {
      s_exp.tl(true,'o','Refine Search Link');
    }
  } else if(linkType=="hotelPosition") {
    s_exp.linkTrackVars='prop16,eVar28,eVar39,eVar61,prop75';
    s_exp.linkTrackEvents='None';
    s_exp.prop16=linkID;
    s_exp.eVar28=linkID;
    s_exp.eVar39=clickedPosition;
    if ((typeof dctk !== 'undefined') && (typeof dctk.trackAction !== 'undefined')){
      dctk.trackEvent('o', 'Clicked hotel position', getTrackVarsFroms_exp(s_exp));

    } else {
      s_exp.tl(true,'o','Clicked hotel position');
    }
  }
  return;
}
s_exp.trackClick=s_exp_trackClick
/* End Link Tracking Functions */

/* Abacus Tracking */
function PLSetABTestIDAndValue(v) {
  if( s_exp.prop34 == "" || s_exp.prop34 == undefined ) {
    s_exp.prop34 = v;
    s_exp.eVar34 = v;
  } else {
    if( s_exp.prop34.indexOf(v)==(-1) ) {
      s_exp.prop34 = s_exp.prop34 + "|" + v;
      s_exp.eVar34 = s_exp.eVar34 + "|" + v;
    }
  }
}



/* Plugin Config */
s_exp.usePlugins=true
function s_exp_doPlugins(s_exp) {

//Maybe we'll want to do siteSpect on Egencia?
  if(typeof window.s_exp_siteSpect !== 'undefined'){
    s_exp.eVar23 = window.s_exp_siteSpect;
  }

//Good to have cookies all parsed out and pretty
  var cookies = document.cookie.split(/;\s*/);

//Sometimes we like to reference s_exp.referrer
  s_exp.referrer = document.referrer;

  s_exp.eVar61 = hostname.replace(/([\w\d]+.)/,"");

  s_exp.eVar50 = 'desktop';

  if (/karmalab.net/.test(hostname)){
    s_exp.tpid = 'dev';
  }


  // Set s_exp.prop12 to GUID from MC1 cookie
  // This overrides any s_exp.prop12 set in scripts in the HTML by the server, as long as the cookie is available.
  // s_exp.prop12 is often set incorrectly by the FTLs as ${this.getGuid()}
  var guid = "";
  if (s_exp.prop12 != undefined) guid = s_exp.prop12;

  for(var i = 0, n = cookies.length; i < n; i++) {
    if(cookies[i].indexOf("MC1=") == 0) {
      guid = cookies[i].split("=")[2];
      break;
    }
  }
  s_exp.prop12 = guid;
  // End s_exp.prop12 to GUID


  /* override referrer if needed */
  s_exp.referrer=s_exp.getQueryParam("origref");

  /* server */
  s_exp.server=document.domain;



  /* rfrr IDs */
  if(!s_exp.eVar28) {
    if(s_exp.getQueryParam("rfrr").length > 0)
      s_exp.eVar28=s_exp.getQueryParam("rfrr")
    if(!s_exp.eVar28 && window.qscr_rfrr) {
      s_exp.eVar28=qscr_rfrr;
    }
  }
  s_exp.prop16=s_exp.eVar28;


  /* pageName in conversion variables */
  s_exp.eVar17=s_exp.pageName;
  s_exp.eVar18=s_exp.pageName;

  /* Mobile device orientation tracking */
  s_exp.prop39 = s_exp.mobileOrientation;


  //Get Android OS version.  If iOS don't care for now, just record as iOS.
  if(navigator.userAgent.match(/Android [\d+\.]{3,5}/) !== null && navigator.userAgent.match(/Android [\d+\.]{3,5}/).length > 0){
    s_exp.prop66 = navigator.userAgent.match(/Android [\d+\.]{3,5}/)[0];
  } else if (/iPhone/.test(navigator.userAgent)){
    s_exp.prop66 = 'iOS';
  }

  // Keep expUserId cookie and prop13 in sync. This code should be kept in sync
  // with DCTK lib.js. See https://mingle/projects/ewe_data_capture_solutions/cards/2443
  // for details.

};

s_exp.doPlugins=s_exp_doPlugins;

/** ************************ PLUGINS SECTION ************************ */

/**
 * Check Mobile Orientation Created - Fraser Kemp
 */

s_exp.checkMobile = (/iphone|ipad|ipod|android|blackberry|mini|windows\sce|palm/i.test(navigator.userAgent.toLowerCase()));
s_exp.mobileOrientation = (s_exp.checkMobile) ? (window.innerHeight > window.innerWidth) ? "Portrait" : "Landscape" : "" ;

/**
 * Function to clear all omniture variables.
 *
 * @type {*}
 */
s_exp.clearOmnitureObject = function(){
  for(var i = 0; i < 100; i++) { s_exp['prop' + i] = ''; s_exp['eVar' + i] = ''; }
  s_exp.products = ''; s_exp.events= ''; s_exp.linkTrackVars = ''; s_exp.server = '';
  s_exp.channel = ''; s_exp.pageName = ''; s_exp.pageType = '';
  s_exp.campaign = ''; s_exp.purchaseID = '';
}


/*
 * Plugin: getValOnce 0.2 - get a value once per session or number of days
 */
s_exp.getValOnce=new Function("v","c","e",""
+"var s=this,k=s.c_r(c),a=new Date;e=e?e:0;if(v){a.setTime(a.getTime("
+")+e*86400000);s.c_w(c,v,e?a:0);}return v==k?'':v");

/*
 * Plugin Utility: split v1.5
 */
s_exp.split=new Function("l","d",""
+"var i,x=0,a=new Array;while(l){i=l.indexOf(d);i=i>-1?i:l.length;a[x"
+"++]=l.substring(0,i);l=l.substring(i+d.length);}return a");

/*
 * Plugin Utility: apl v1.1
 */
s_exp.apl=new Function("L","v","d","u",""
+"var s=this,m=0;if(!L)L='';if(u){var i,n,a=s.split(L,d);for(i=0;i<a."
+"length;i++){n=a[i];m=m||(u==1?(n==v):(n.toLowerCase()==v.toLowerCas"
+"e()));}}if(!m)L=L?L+d+v:v;return L");

/*
 * Plugin Utility: Replace v1.0
 */
s_exp.repl=new Function("x","o","n",""
+"var i=x.indexOf(o),l=n.length;while(x&&i>=0){x=x.substring(0,i)+n+x."
+"substring(i+o.length);i=x.indexOf(o,i+l)}return x");

/* global s_exp */

(function () {

  /**
   * The difference in time DCTK will use when determing current time. This
   * effectively allows us to "time travel" during testing. See `dctk._now()`.
   */
  s_exp._timeDelta = 0;

  /**
   * @param {int} interval The time from now in milliseconds
   * @return {Date} The date past the interval
   */
  s_exp._getDateFromNow = function (interval) {
    return new Date(new Date().getTime() + interval);
  };

  /**
   * TODO Make sure it's being used throughout all DCTK
   *
   * @return {number} The current timestamp after applying `dctk._timeDelta`
   */
  s_exp._now = function () {
    return new Date().getTime() + this._timeDelta;
  };

  // Constants to make working with timespans easier
  s_exp._MINUTES = 60 * 1000;
  s_exp._HOURS = 60 * s_exp._MINUTES;
  s_exp._DAYS = 24 * s_exp._HOURS;

  /**
   * @param {string} url
   * @returns {string|undefined} `url` host or `undefined` if `url`
   *     is not an absolute URL
   */
  s_exp._getUrlHost = function (url) {
    if (/^[a-z]+:\/\/([^:/]+)/.test(url)) return RegExp.$1;
  };

  /**
   * @param {string} domain
   * @param {string} [domain] The current domain is used by default
   * @returns {string} The primary domain of domain (e.g. www.expedia.co.uk
   *     -> expedia.co.uk) or the whole domain if it can't be determined
   *
   * A more exhaustive implementation would use https://publicsuffix.org/
   * to determine what's an effective TLD but here we have enough
   * considering Expedia domains.
   *
   * TODO Ignore IPs?
   */
  s_exp._getPrimaryDomain = function (domain) {
    if (!domain) domain = document.domain;
    var matches = domain.match(/[^.]+(\.(co|com))?\.[^.]+$/);
    return matches ? matches[0] : domain;
  };


  s_exp._cookies = {
    /**
     * Sets a cookie
     *
     * @param {Object} params
     * @param {string} params.name The name of the cookie
     * @param {string} params.value The value to store
     * @param {string} [params.path]
     * @param {date} [params.expires]
     */
    set: function (params) {
      var fields = [];
      var addField = function (key, value) { fields.push(key + '=' + value); };
      addField(encodeURIComponent(params.name), encodeURIComponent(params.value));
      // We don't set the domain when it's already the current one as in certain
      // cases (e.g. localhost) the cookie won't be set even if the outcome
      // of setting it would be the same.
      if (params.domain && params.domain != document.domain) addField('domain', params.domain);
      if (params.path) addField('path', params.path);
      if (params.maxAge) params.expires = s_exp._getDateFromNow(params.maxAge);
      if (params.expires) addField('expires', params.expires.toUTCString());
      document.cookie = fields.join(';');
    },

    /**
     * @param {Object|string} params A list of params or the same as params.name
     * @param {string} params.name The name of the cookie
     * @param {string} [params.cookies] String containing the cookies or
     *     document.cookie by default.
     * @return {string|undefined} The value of the cookie or undefined if it
     *     doesn't exist
     */
    get: function (params) {
      if (typeof params == 'string') params = {name: params};
      if (params.cookies == null) params.cookies = document.cookie;
      var cookies = params.cookies.split(';');
      for (var i = 0; i < cookies.length; i++) {
        // All these " *" are to trim without using trim() as IE8 doesn't
        // support it. Also, "=" is considered optional as IE8 still follows
        // the now obsolete RFC 2965.
        var matches = cookies[i].match(/ *([^=]*) *(= *(.*))? */);
        var cookieName = decodeURIComponent(matches[1]);
        var cookieValue = matches[3] == null ? '' : decodeURIComponent(matches[3]);
        if (cookieName === params.name) return cookieValue;
      }
    },

    'delete': function (params) {
      this.set({
        name: params.name,
        value: '',
        path: params.path,
        expires: new Date(0)
      });
    }
  };
})();

/* global s_exp */

(function () {
  var SEARCH_ENGINE_DOMAIN_PATTERNS = ["google", "bing", "yahoo", "naver", "ask.com",
    "aol.co", "duckduckgo", "baidu", "webcrawler", "mywebsearch", "dogpile",
    "msn", "daum.net", "comcast.net", "docomo.ne.jp", "libero.it", "avg.com",
    "suche.web.de", "virgilio.it", "rakuten"];

  var BRANDED_LANDING_PAGE_NAMES = ["Homepage", "page.Hotels", "page.Cars", "page.Flights",
    "page.Packages", "page.Expedia-Holiday", "page.Short-Breaks", "last-minute",
    "page.Travel.Deals", "page.Activities", "bolt-on:flights:launch", "page.Cruise",
    "/deals", "/p/vacation-packages", "page.Cruises", "page.Deals", "page.Rewards",
    "groupon.tab", "/p/info-other/expedia-coupon.htm", "p/promos/expedia-summer",
    "page.domestic", "tools/itinerary", "p/promos/campaign", "rental-car"];

  // See dctk._urlHasChanged()
  var originalUrl = location.href;

  /**
   * @returns {string|undefined} The domain of the search engine
   *     that redirected the user to the current page, or `undefined`
   *     if the user doesn't come from a search engine.
   */
  s_exp._getSearchEngineDomain = function (referrer) {
    if (referrer == null) referrer = s_exp._getReferrer();
    var host = s_exp._getUrlHost(referrer);
    var primaryDomain = host && s_exp._getPrimaryDomain(host);
    for (var i = 0; i < SEARCH_ENGINE_DOMAIN_PATTERNS.length; i++) {
      if ((primaryDomain || '').match(SEARCH_ENGINE_DOMAIN_PATTERNS[i])) {
        return primaryDomain;
      }
    }
  };

  /**
   * @returns {boolean} Whether the current page is a branded
   *     landing page.
   */
  s_exp._isBrandedLandingPage = function (pageName) {
    if (!pageName) pageName = s_exp.pageName;
    for (var i = 0; i < BRANDED_LANDING_PAGE_NAMES.length; i++) {
      if (pageName == BRANDED_LANDING_PAGE_NAMES[i]) return true;
    }
  };

  /**
   * @returns {string} The original HTTP referer or an empty string if the original
   *     URL has changed
   */
  s_exp._getReferrer = function () {
    if (this._urlHasChanged()) {
      // Ideally we would return the URL prior to the change but it doesn't
      // seem possible to track it properly. We don't need it anyway.
      return '';
    }
    return document.referrer;
  };

  /**
   * @returns {boolean} Whether the URL has changed since the page load (e.g.
   *     because history.pushState() was called)
   */
  s_exp._urlHasChanged = function () {
    return location.href != originalUrl;
  };
})();
/* eslint-env browser */
/* global s_exp */

(function () {
  /**
   * Legacy interface for getQueryParam()
   *
   * See http://microsite.omniture.com/t2/help/en_US/sc/implement/getQueryParam.html
   * and http://microsite.omniture.com/t2/help/en_US/sc/implement/util_getqueryparam.html
   *
   * @param {String} params
   * @param {String} [delimiter] Ignored as the new version doesn't support it
   * @param {String} [url]
   */
  s_exp.getQueryParam = function(params, delimiter, url) {
    params = params.split(',');
    var values = [];
    for (var i = 0; i < params.length; i++) {
      var value = getSingleQueryParam(params[i], url);
      if (value.length) values.push(value);
    }
    return values.join(delimiter || '');
  };

  var getSingleQueryParam = function(param, url) {

    var getQueryString = function (url) {
      var matches = url.match(/\?([^#]*)(#.*)?$/);
      return matches ? matches[1] : '';
    };

    var lowerCaseQueryStringNames = function (queryString) {
      var params = queryString.split('&');
      for (var i = 0; i < params.length; i++) {
        var parts = params[i].split('=');
        parts[0] = parts[0].toLowerCase();
        params[i] = parts.join('=');
      }
      return params.join('&');
    };

    // Notice the delimiter argument in AppMeasurement's version doesn't
    // correspond to H's one.
    var normalizedQueryString = '?' + lowerCaseQueryStringNames(getQueryString(url || location.href));
    return s_exp.Util.getQueryParam(param.toLowerCase(), normalizedQueryString /* , delimiter */);
  };
})();
/*
 ============== DO NOT ALTER ANYTHING BELOW THIS LINE ! ===============

 AppMeasurement for JavaScript version: 1.4.1
 Copyright 1996-2013 Adobe, Inc. All Rights Reserved
 More info available at http://www.omniture.com
 */
function AppMeasurement(){var s=this;s.version="1.4.1";var w=window;if(!w.s_c_in)w.s_c_il=[],w.s_c_in=0;s._il=w.s_c_il;s._in=w.s_c_in;s._il[s._in]=s;w.s_c_in++;s._c="s_c";var k=w.sb;k||(k=null);var m=w,i,o;try{i=m.parent;for(o=m.location;i&&i.location&&o&&""+i.location!=""+o&&m.location&&""+i.location!=""+m.location&&i.location.host==o.host;)m=i,i=m.parent}catch(p){}s.eb=function(s){try{console.log(s)}catch(a){}};s.ta=function(s){return""+parseInt(s)==""+s};s.replace=function(s,a,c){if(!s||s.indexOf(a)<
  0)return s;return s.split(a).join(c)};s.escape=function(b){var a,c;if(!b)return b;b=encodeURIComponent(b);for(a=0;a<7;a++)c="+~!*()'".substring(a,a+1),b.indexOf(c)>=0&&(b=s.replace(b,c,"%"+c.charCodeAt(0).toString(16).toUpperCase()));return b};s.unescape=function(b){if(!b)return b;b=b.indexOf("+")>=0?s.replace(b,"+"," "):b;try{return decodeURIComponent(b)}catch(a){}return unescape(b)};s.Va=function(){var b=w.location.hostname,a=s.fpCookieDomainPeriods,c;if(!a)a=s.cookieDomainPeriods;if(b&&!s.cookieDomain&&
  !/^[0-9.]+$/.test(b)&&(a=a?parseInt(a):2,a=a>2?a:2,c=b.lastIndexOf("."),c>=0)){for(;c>=0&&a>1;)c=b.lastIndexOf(".",c-1),a--;s.cookieDomain=c>0?b.substring(c):b}return s.cookieDomain};s.c_r=s.cookieRead=function(b){b=s.escape(b);var a=" "+s.d.cookie,c=a.indexOf(" "+b+"="),e=c<0?c:a.indexOf(";",c);b=c<0?"":s.unescape(a.substring(c+2+b.length,e<0?a.length:e));return b!="[[B]]"?b:""};s.c_w=s.cookieWrite=function(b,a,c){var e=s.Va(),d=s.cookieLifetime,f;a=""+a;d=d?(""+d).toUpperCase():"";c&&d!="SESSION"&&
d!="NONE"&&((f=a!=""?parseInt(d?d:0):-60)?(c=new Date,c.setTime(c.getTime()+f*1E3)):c==1&&(c=new Date,f=c.getYear(),c.setYear(f+5+(f<1900?1900:0))));if(b&&d!="NONE")return s.d.cookie=b+"="+s.escape(a!=""?a:"[[B]]")+"; path=/;"+(c&&d!="SESSION"?" expires="+c.toGMTString()+";":"")+(e?" domain="+e+";":""),s.cookieRead(b)==a;return 0};s.C=[];s.B=function(b,a,c){if(s.ma)return 0;if(!s.maxDelay)s.maxDelay=250;var e=0,d=(new Date).getTime()+s.maxDelay,f=s.d.qb,g=["webkitvisibilitychange","visibilitychange"];
  if(!f)f=s.d.rb;if(f&&f=="prerender"){if(!s.X){s.X=1;for(c=0;c<g.length;c++)s.d.addEventListener(g[c],function(){var a=s.d.qb;if(!a)a=s.d.rb;if(a=="visible")s.X=0,s.delayReady()})}e=1;d=0}else c||s.q("_d")&&(e=1);e&&(s.C.push({m:b,a:a,t:d}),s.X||setTimeout(s.delayReady,s.maxDelay));return e};s.delayReady=function(){var b=(new Date).getTime(),a=0,c;for(s.q("_d")&&(a=1);s.C.length>0;){c=s.C.shift();if(a&&!c.t&&c.t>b){s.C.unshift(c);setTimeout(s.delayReady,parseInt(s.maxDelay/2));break}s.ma=1;s[c.m].apply(s,
  c.a);s.ma=0}};s.setAccount=s.sa=function(b){var a,c;if(!s.B("setAccount",arguments))if(s.account=b,s.allAccounts){a=s.allAccounts.concat(b.split(","));s.allAccounts=[];a.sort();for(c=0;c<a.length;c++)(c==0||a[c-1]!=a[c])&&s.allAccounts.push(a[c])}else s.allAccounts=b.split(",")};s.foreachVar=function(b,a){var c,e,d,f,g="";d=e="";if(s.lightProfileID)c=s.H,(g=s.lightTrackVars)&&(g=","+g+","+s.ba.join(",")+",");else{c=s.c;if(s.pe||s.linkType)if(g=s.linkTrackVars,e=s.linkTrackEvents,s.pe&&(d=s.pe.substring(0,
    1).toUpperCase()+s.pe.substring(1),s[d]))g=s[d].pb,e=s[d].ob;g&&(g=","+g+","+s.z.join(",")+",");e&&g&&(g+=",events,")}a&&(a=","+a+",");for(e=0;e<c.length;e++)d=c[e],(f=s[d])&&(!g||g.indexOf(","+d+",")>=0)&&(!a||a.indexOf(","+d+",")>=0)&&b(d,f)};s.J=function(b,a,c,e,d){var f="",g,j,w,q,i=0;b=="contextData"&&(b="c");if(a){for(g in a)if(!Object.prototype[g]&&(!d||g.substring(0,d.length)==d)&&a[g]&&(!c||c.indexOf(","+(e?e+".":"")+g+",")>=0)){w=!1;if(i)for(j=0;j<i.length;j++)g.substring(0,i[j].length)==
i[j]&&(w=!0);if(!w&&(f==""&&(f+="&"+b+"."),j=a[g],d&&(g=g.substring(d.length)),g.length>0))if(w=g.indexOf("."),w>0)j=g.substring(0,w),w=(d?d:"")+j+".",i||(i=[]),i.push(w),f+=s.J(j,a,c,e,w);else if(typeof j=="boolean"&&(j=j?"true":"false"),j){if(e=="retrieveLightData"&&d.indexOf(".contextData.")<0)switch(w=g.substring(0,4),q=g.substring(4),g){case "transactionID":g="xact";break;case "channel":g="ch";break;case "campaign":g="v0";break;default:s.ta(q)&&(w=="prop"?g="c"+q:w=="eVar"?g="v"+q:w=="list"?
  g="l"+q:w=="hier"&&(g="h"+q,j=j.substring(0,255)))}f+="&"+s.escape(g)+"="+s.escape(j)}}f!=""&&(f+="&."+b)}return f};s.Xa=function(){var b="",a,c,e,d,f,g,j,w,i="",k="",m=c="";if(s.lightProfileID)a=s.H,(i=s.lightTrackVars)&&(i=","+i+","+s.ba.join(",")+",");else{a=s.c;if(s.pe||s.linkType)if(i=s.linkTrackVars,k=s.linkTrackEvents,s.pe&&(c=s.pe.substring(0,1).toUpperCase()+s.pe.substring(1),s[c]))i=s[c].pb,k=s[c].ob;i&&(i=","+i+","+s.z.join(",")+",");k&&(k=","+k+",",i&&(i+=",events,"));s.events2&&(m+=(m!=
""?",":"")+s.events2)}s.AudienceManagement&&s.AudienceManagement.isReady()&&(b+=s.J("d",s.AudienceManagement.getEventCallConfigParams()));for(c=0;c<a.length;c++){d=a[c];f=s[d];e=d.substring(0,4);g=d.substring(4);!f&&d=="events"&&m&&(f=m,m="");if(f&&(!i||i.indexOf(","+d+",")>=0)){switch(d){case "supplementalDataID":d="sdid";break;case "timestamp":d="ts";break;case "dynamicVariablePrefix":d="D";break;case "visitorID":d="vid";break;case "marketingCloudVisitorID":d="mid";break;case "analyticsVisitorID":d=
  "aid";break;case "audienceManagerLocationHint":d="aamlh";break;case "audienceManagerBlob":d="aamb";break;case "authState":d="as";break;case "pageURL":d="g";if(f.length>255)s.pageURLRest=f.substring(255),f=f.substring(0,255);break;case "pageURLRest":d="-g";break;case "referrer":d="r";break;case "vmk":case "visitorMigrationKey":d="vmt";break;case "visitorMigrationServer":d="vmf";s.ssl&&s.visitorMigrationServerSecure&&(f="");break;case "visitorMigrationServerSecure":d="vmf";!s.ssl&&s.visitorMigrationServer&&
(f="");break;case "charSet":d="ce";break;case "visitorNamespace":d="ns";break;case "cookieDomainPeriods":d="cdp";break;case "cookieLifetime":d="cl";break;case "variableProvider":d="vvp";break;case "currencyCode":d="cc";break;case "channel":d="ch";break;case "transactionID":d="xact";break;case "campaign":d="v0";break;case "latitude":d="lat";break;case "longitude":d="lon";break;case "resolution":d="s";break;case "colorDepth":d="c";break;case "javascriptVersion":d="j";break;case "javaEnabled":d="v";
  break;case "cookiesEnabled":d="k";break;case "browserWidth":d="bw";break;case "browserHeight":d="bh";break;case "connectionType":d="ct";break;case "homepage":d="hp";break;case "events":m&&(f+=(f!=""?",":"")+m);if(k){g=f.split(",");f="";for(e=0;e<g.length;e++)j=g[e],w=j.indexOf("="),w>=0&&(j=j.substring(0,w)),w=j.indexOf(":"),w>=0&&(j=j.substring(0,w)),k.indexOf(","+j+",")>=0&&(f+=(f?",":"")+g[e])}break;case "events2":f="";break;case "contextData":b+=s.J("c",s[d],i,d);f="";break;case "lightProfileID":d=
  "mtp";break;case "lightStoreForSeconds":d="mtss";s.lightProfileID||(f="");break;case "lightIncrementBy":d="mti";s.lightProfileID||(f="");break;case "retrieveLightProfiles":d="mtsr";break;case "deleteLightProfiles":d="mtsd";break;case "retrieveLightData":s.retrieveLightProfiles&&(b+=s.J("mts",s[d],i,d));f="";break;default:s.ta(g)&&(e=="prop"?d="c"+g:e=="eVar"?d="v"+g:e=="list"?d="l"+g:e=="hier"&&(d="h"+g,f=f.substring(0,255)))}f&&(b+="&"+d+"="+(d.substring(0,3)!="pev"?s.escape(f):f))}d=="pev3"&&s.g&&
(b+=s.g)}return b};s.u=function(s){var a=s.tagName;if(""+s.wb!="undefined"||""+s.ib!="undefined"&&(""+s.ib).toUpperCase()!="HTML")return"";a=a&&a.toUpperCase?a.toUpperCase():"";a=="SHAPE"&&(a="");a&&((a=="INPUT"||a=="BUTTON")&&s.type&&s.type.toUpperCase?a=s.type.toUpperCase():!a&&s.href&&(a="A"));return a};s.oa=function(s){var a=s.href?s.href:"",c,e,d;c=a.indexOf(":");e=a.indexOf("?");d=a.indexOf("/");if(a&&(c<0||e>=0&&c>e||d>=0&&c>d))e=s.protocol&&s.protocol.length>1?s.protocol:l.protocol?l.protocol:
  "",c=l.pathname.lastIndexOf("/"),a=(e?e+"//":"")+(s.host?s.host:l.host?l.host:"")+(h.substring(0,1)!="/"?l.pathname.substring(0,c<0?0:c)+"/":"")+a;return a};s.D=function(b){var a=s.u(b),c,e,d="",f=0;if(a){c=b.protocol;e=b.onclick;if(b.href&&(a=="A"||a=="AREA")&&(!e||!c||c.toLowerCase().indexOf("javascript")<0))d=s.oa(b);else if(e)d=s.replace(s.replace(s.replace(s.replace(""+e,"\r",""),"\n",""),"\t","")," ",""),f=2;else if(a=="INPUT"||a=="SUBMIT"){if(b.value)d=b.value;else if(b.innerText)d=b.innerText;
else if(b.textContent)d=b.textContent;f=3}else if(b.src&&a=="IMAGE")d=b.src;if(d)return{id:d.substring(0,100),type:f}}return 0};s.tb=function(b){for(var a=s.u(b),c=s.D(b);b&&!c&&a!="BODY";)if(b=b.parentElement?b.parentElement:b.parentNode)a=s.u(b),c=s.D(b);if(!c||a=="BODY")b=0;if(b&&(a=b.onclick?""+b.onclick:"",a.indexOf(".tl(")>=0||a.indexOf(".trackLink(")>=0))b=0;return b};s.hb=function(){var b,a,c=s.linkObject,e=s.linkType,d=s.linkURL,f,g;s.ca=1;if(!c)s.ca=0,c=s.clickObject;if(c){b=s.u(c);for(a=
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                       s.D(c);c&&!a&&b!="BODY";)if(c=c.parentElement?c.parentElement:c.parentNode)b=s.u(c),a=s.D(c);if(!a||b=="BODY")c=0;if(c){var j=c.onclick?""+c.onclick:"";if(j.indexOf(".tl(")>=0||j.indexOf(".trackLink(")>=0)c=0}}else s.ca=1;!d&&c&&(d=s.oa(c));d&&!s.linkLeaveQueryString&&(f=d.indexOf("?"),f>=0&&(d=d.substring(0,f)));if(!e&&d){var i=0,k=0,m;if(s.trackDownloadLinks&&s.linkDownloadFileTypes){j=d.toLowerCase();f=j.indexOf("?");g=j.indexOf("#");f>=0?g>=0&&g<f&&(f=g):f=g;f>=0&&(j=j.substring(0,f));f=s.linkDownloadFileTypes.toLowerCase().split(",");
  for(g=0;g<f.length;g++)(m=f[g])&&j.substring(j.length-(m.length+1))=="."+m&&(e="d")}if(s.trackExternalLinks&&!e&&(j=d.toLowerCase(),s.ra(j))){if(!s.linkInternalFilters)s.linkInternalFilters=w.location.hostname;f=0;s.linkExternalFilters?(f=s.linkExternalFilters.toLowerCase().split(","),i=1):s.linkInternalFilters&&(f=s.linkInternalFilters.toLowerCase().split(","));if(f){for(g=0;g<f.length;g++)m=f[g],j.indexOf(m)>=0&&(k=1);k?i&&(e="e"):i||(e="e")}}}s.linkObject=c;s.linkURL=d;s.linkType=e;if(s.trackClickMap||
  s.trackInlineStats)if(s.g="",c){e=s.pageName;d=1;c=c.sourceIndex;if(!e)e=s.pageURL,d=0;if(w.s_objectID)a.id=w.s_objectID,c=a.type=1;if(e&&a&&a.id&&b)s.g="&pid="+s.escape(e.substring(0,255))+(d?"&pidt="+d:"")+"&oid="+s.escape(a.id.substring(0,100))+(a.type?"&oidt="+a.type:"")+"&ot="+b+(c?"&oi="+c:"")}};s.Ya=function(){var b=s.ca,a=s.linkType,c=s.linkURL,e=s.linkName;if(a&&(c||e))a=a.toLowerCase(),a!="d"&&a!="e"&&(a="o"),s.pe="lnk_"+a,s.pev1=c?s.escape(c):"",s.pev2=e?s.escape(e):"",b=1;s.abort&&(b=
  0);if(s.trackClickMap||s.trackInlineStats){a={};c=0;var d=s.cookieRead("s_sq"),f=d?d.split("&"):0,g,j,w;d=0;if(f)for(g=0;g<f.length;g++)j=f[g].split("="),e=s.unescape(j[0]).split(","),j=s.unescape(j[1]),a[j]=e;e=s.account.split(",");if(b||s.g){b&&!s.g&&(d=1);for(j in a)if(!Object.prototype[j])for(g=0;g<e.length;g++){d&&(w=a[j].join(","),w==s.account&&(s.g+=(j.charAt(0)!="&"?"&":"")+j,a[j]=[],c=1));for(f=0;f<a[j].length;f++)w=a[j][f],w==e[g]&&(d&&(s.g+="&u="+s.escape(w)+(j.charAt(0)!="&"?"&":"")+j+
"&u=0"),a[j].splice(f,1),c=1)}b||(c=1);if(c){d="";g=2;!b&&s.g&&(d=s.escape(e.join(","))+"="+s.escape(s.g),g=1);for(j in a)!Object.prototype[j]&&g>0&&a[j].length>0&&(d+=(d?"&":"")+s.escape(a[j].join(","))+"="+s.escape(j),g--);s.cookieWrite("s_sq",d)}}}return b};s.Za=function(){if(!s.nb){var b=new Date,a=m.location,c,e,d=e=c="",f="",g="",w="1.2",i=s.cookieWrite("s_cc","true",0)?"Y":"N",k="",n="";if(b.setUTCDate&&(w="1.3",(0).toPrecision&&(w="1.5",b=[],b.forEach))){w="1.6";e=0;c={};try{e=new Iterator(c),
e.next&&(w="1.7",b.reduce&&(w="1.8",w.trim&&(w="1.8.1",Date.parse&&(w="1.8.2",Object.create&&(w="1.8.5")))))}catch(o){}}c=screen.width+"x"+screen.height;d=navigator.javaEnabled()?"Y":"N";e=screen.pixelDepth?screen.pixelDepth:screen.colorDepth;f=s.w.innerWidth?s.w.innerWidth:s.d.documentElement.offsetWidth;g=s.w.innerHeight?s.w.innerHeight:s.d.documentElement.offsetHeight;try{s.b.addBehavior("#default#homePage"),k=s.b.ub(a)?"Y":"N"}catch(p){}try{s.b.addBehavior("#default#clientCaps"),n=s.b.connectionType}catch(r){}s.resolution=
  c;s.colorDepth=e;s.javascriptVersion=w;s.javaEnabled=d;s.cookiesEnabled=i;s.browserWidth=f;s.browserHeight=g;s.connectionType=n;s.homepage=k;s.nb=1}};s.I={};s.loadModule=function(b,a){var c=s.I[b];if(!c){c=w["AppMeasurement_Module_"+b]?new w["AppMeasurement_Module_"+b](s):{};s.I[b]=s[b]=c;c.Fa=function(){return c.Ja};c.Ka=function(a){if(c.Ja=a)s[b+"_onLoad"]=a,s.B(b+"_onLoad",[s,c],1)||a(s,c)};try{Object.defineProperty?Object.defineProperty(c,"onLoad",{get:c.Fa,set:c.Ka}):c._olc=1}catch(e){c._olc=
  1}}a&&(s[b+"_onLoad"]=a,s.B(b+"_onLoad",[s,c],1)||a(s,c))};s.q=function(b){var a,c;for(a in s.I)if(!Object.prototype[a]&&(c=s.I[a])){if(c._olc&&c.onLoad)c._olc=0,c.onLoad(s,c);if(c[b]&&c[b]())return 1}return 0};s.bb=function(){var b=Math.floor(Math.random()*1E13),a=s.visitorSampling,c=s.visitorSamplingGroup;c="s_vsn_"+(s.visitorNamespace?s.visitorNamespace:s.account)+(c?"_"+c:"");var e=s.cookieRead(c);if(a){e&&(e=parseInt(e));if(!e){if(!s.cookieWrite(c,b))return 0;e=b}if(e%1E4>v)return 0}return 1};
  s.K=function(b,a){var c,e,d,f,g,w;for(c=0;c<2;c++){e=c>0?s.ia:s.c;for(d=0;d<e.length;d++)if(f=e[d],(g=b[f])||b["!"+f]){if(!a&&(f=="contextData"||f=="retrieveLightData")&&s[f])for(w in s[f])g[w]||(g[w]=s[f][w]);s[f]=g}}};s.Aa=function(b,a){var c,e,d,f;for(c=0;c<2;c++){e=c>0?s.ia:s.c;for(d=0;d<e.length;d++)f=e[d],b[f]=s[f],!a&&!b[f]&&(b["!"+f]=1)}};s.Ua=function(s){var a,c,e,d,f,g=0,w,i="",k="";if(s&&s.length>255&&(a=""+s,c=a.indexOf("?"),c>0&&(w=a.substring(c+1),a=a.substring(0,c),d=a.toLowerCase(),
      e=0,d.substring(0,7)=="http://"?e+=7:d.substring(0,8)=="https://"&&(e+=8),c=d.indexOf("/",e),c>0&&(d=d.substring(e,c),f=a.substring(c),a=a.substring(0,c),d.indexOf("google")>=0?g=",q,ie,start,search_key,word,kw,cd,":d.indexOf("yahoo.co")>=0&&(g=",p,ei,"),g&&w)))){if((s=w.split("&"))&&s.length>1){for(e=0;e<s.length;e++)d=s[e],c=d.indexOf("="),c>0&&g.indexOf(","+d.substring(0,c)+",")>=0?i+=(i?"&":"")+d:k+=(k?"&":"")+d;i&&k?w=i+"&"+k:k=""}c=253-(w.length-k.length)-a.length;s=a+(c>0?f.substring(0,c):
    "")+"?"+w}return s};s.U=!1;s.O=!1;s.Ia=function(b){s.marketingCloudVisitorID=b;s.O=!0;s.k()};s.R=!1;s.L=!1;s.Ca=function(b){s.analyticsVisitorID=b;s.L=!0;s.k()};s.T=!1;s.N=!1;s.Ea=function(b){s.audienceManagerLocationHint=b;s.N=!0;s.k()};s.S=!1;s.M=!1;s.Da=function(b){s.audienceManagerBlob=b;s.M=!0;s.k()};s.isReadyToTrack=function(){var b=!0,a=s.visitor;if(a&&a.isAllowed()){if(!s.U&&!s.marketingCloudVisitorID&&a.getMarketingCloudVisitorID&&(s.U=!0,s.marketingCloudVisitorID=a.getMarketingCloudVisitorID([s,
      s.Ia]),s.marketingCloudVisitorID))s.O=!0;if(!s.R&&!s.analyticsVisitorID&&a.getAnalyticsVisitorID&&(s.R=!0,s.analyticsVisitorID=a.getAnalyticsVisitorID([s,s.Ca]),s.analyticsVisitorID))s.L=!0;if(!s.T&&!s.audienceManagerLocationHint&&a.getAudienceManagerLocationHint&&(s.T=!0,s.audienceManagerLocationHint=a.getAudienceManagerLocationHint([s,s.Ea]),s.audienceManagerLocationHint))s.N=!0;if(!s.S&&!s.audienceManagerBlob&&a.getAudienceManagerBlob&&(s.S=!0,s.audienceManagerBlob=a.getAudienceManagerBlob([s,
      s.Da]),s.audienceManagerBlob))s.M=!0;if(s.U&&!s.O&&!s.marketingCloudVisitorID||s.R&&!s.L&&!s.analyticsVisitorID||s.T&&!s.N&&!s.audienceManagerLocationHint||s.S&&!s.M&&!s.audienceManagerBlob)b=!1}return b};s.j=k;s.l=0;s.callbackWhenReadyToTrack=function(b,a,c){var e;e={};e.Oa=b;e.Na=a;e.La=c;if(s.j==k)s.j=[];s.j.push(e);if(s.l==0)s.l=setInterval(s.k,100)};s.k=function(){var b;if(s.isReadyToTrack()){if(s.l)clearInterval(s.l),s.l=0;if(s.j!=k)for(;s.j.length>0;)b=s.j.shift(),b.Na.apply(b.Oa,b.La)}};s.Ga=
    function(b){var a,c,e=k,d=k;if(!s.isReadyToTrack()){a=[];if(b!=k)for(c in e={},b)e[c]=b[c];d={};s.Aa(d,!0);a.push(e);a.push(d);s.callbackWhenReadyToTrack(s,s.track,a);return!0}return!1};s.Wa=function(){var b=s.cookieRead("s_fid"),a="",c="",e;e=8;var d=4;if(!b||b.indexOf("-")<0){for(b=0;b<16;b++)e=Math.floor(Math.random()*e),a+="0123456789ABCDEF".substring(e,e+1),e=Math.floor(Math.random()*d),c+="0123456789ABCDEF".substring(e,e+1),e=d=16;b=a+"-"+c}s.cookieWrite("s_fid",b,1)||(b=0);return b};s.t=s.track=
    function(b,a){var c,e=new Date,d="s"+Math.floor(e.getTime()/108E5)%10+Math.floor(Math.random()*1E13),f=e.getYear();f="t="+s.escape(e.getDate()+"/"+e.getMonth()+"/"+(f<1900?f+1900:f)+" "+e.getHours()+":"+e.getMinutes()+":"+e.getSeconds()+" "+e.getDay()+" "+e.getTimezoneOffset());if(s.visitor){if(s.visitor.getAuthState)s.authState=s.visitor.getAuthState();if(!s.supplementalDataID&&s.visitor.getSupplementalDataID)s.supplementalDataID=s.visitor.getSupplementalDataID("AppMeasurement:"+s._in,s.expectSupplementalData?
      !1:!0)}s.q("_s");if(!s.B("track",arguments)){if(!s.Ga(b)){a&&s.K(a);b&&(c={},s.Aa(c,0),s.K(b));if(s.bb()){if(!s.analyticsVisitorID&&!s.marketingCloudVisitorID)s.fid=s.Wa();s.hb();s.usePlugins&&s.doPlugins&&s.doPlugins(s);if(s.account){if(!s.abort){if(s.trackOffline&&!s.timestamp)s.timestamp=Math.floor(e.getTime()/1E3);e=w.location;if(!s.pageURL)s.pageURL=e.href?e.href:e;if(!s.referrer&&!s.Ba)s.referrer=m.document.referrer,s.Ba=1;s.referrer=s.Ua(s.referrer);s.q("_g")}if(s.Ya()&&!s.abort)s.Za(),f+=
      s.Xa(),s.gb(d,f),s.q("_t"),s.referrer=""}}b&&s.K(c,1)}s.abort=s.supplementalDataID=s.timestamp=s.pageURLRest=s.linkObject=s.clickObject=s.linkURL=s.linkName=s.linkType=w.vb=s.pe=s.pev1=s.pev2=s.pev3=s.g=0}};s.tl=s.trackLink=function(b,a,c,e,d){s.linkObject=b;s.linkType=a;s.linkName=c;if(d)s.i=b,s.p=d;return s.track(e)};s.trackLight=function(b,a,c,e){s.lightProfileID=b;s.lightStoreForSeconds=a;s.lightIncrementBy=c;return s.track(e)};s.clearVars=function(){var b,a;for(b=0;b<s.c.length;b++)if(a=s.c[b],
    a.substring(0,4)=="prop"||a.substring(0,4)=="eVar"||a.substring(0,4)=="hier"||a.substring(0,4)=="list"||a=="channel"||a=="events"||a=="eventList"||a=="products"||a=="productList"||a=="purchaseID"||a=="transactionID"||a=="state"||a=="zip"||a=="campaign")s[a]=void 0};s.tagContainerMarker="";s.gb=function(b,a){var c,e=s.trackingServer;c="";var d=s.dc,f="sc.",w=s.visitorNamespace;if(e){if(s.trackingServerSecure&&s.ssl)e=s.trackingServerSecure}else{if(!w)w=s.account,e=w.indexOf(","),e>=0&&(w=w.substring(0,
    e)),w=w.replace(/[^A-Za-z0-9]/g,"");c||(c="2o7.net");d=d?(""+d).toLowerCase():"d1";c=="2o7.net"&&(d=="d1"?d="112":d=="d2"&&(d="122"),f="");e=w+"."+d+"."+f+c}c=s.ssl?"https://":"http://";d=s.AudienceManagement&&s.AudienceManagement.isReady();c+=e+"/b/ss/"+s.account+"/"+(s.mobile?"5.":"")+(d?"10":"1")+"/JS-"+s.version+(s.mb?"T":"")+(s.tagContainerMarker?"-"+s.tagContainerMarker:"")+"/"+b+"?AQB=1&ndh=1&pf=1&"+(d?"callback=s_c_il["+s._in+"].AudienceManagement.passData&":"")+a+"&AQE=1";s.Sa(c);s.Y()};
  s.Sa=function(b){s.e||s.$a();s.e.push(b);s.aa=s.r();s.za()};s.$a=function(){s.e=s.cb();if(!s.e)s.e=[]};s.cb=function(){var b,a;if(s.fa()){try{(a=w.localStorage.getItem(s.da()))&&(b=w.JSON.parse(a))}catch(c){}return b}};s.fa=function(){var b=!0;if(!s.trackOffline||!s.offlineFilename||!w.localStorage||!w.JSON)b=!1;return b};s.pa=function(){var b=0;if(s.e)b=s.e.length;s.v&&b++;return b};s.Y=function(){if(!s.v)if(s.qa=k,s.ea)s.aa>s.G&&s.xa(s.e),s.ha(500);else{var b=s.Ma();if(b>0)s.ha(b);else if(b=s.na())s.v=
    1,s.fb(b),s.jb(b)}};s.ha=function(b){if(!s.qa)b||(b=0),s.qa=setTimeout(s.Y,b)};s.Ma=function(){var b;if(!s.trackOffline||s.offlineThrottleDelay<=0)return 0;b=s.r()-s.wa;if(s.offlineThrottleDelay<b)return 0;return s.offlineThrottleDelay-b};s.na=function(){if(s.e.length>0)return s.e.shift()};s.fb=function(b){if(s.debugTracking){var a="AppMeasurement Debug: "+b;b=b.split("&");var c;for(c=0;c<b.length;c++)a+="\n\t"+s.unescape(b[c]);s.eb(a)}};s.Ha=function(){return s.marketingCloudVisitorID||s.analyticsVisitorID};
  s.Q=!1;var n;try{n=JSON.parse('{"x":"y"}')}catch(r){n=null}n&&n.x=="y"?(s.Q=!0,s.P=function(s){return JSON.parse(s)}):w.$&&w.$.parseJSON?(s.P=function(s){return w.$.parseJSON(s)},s.Q=!0):s.P=function(){return null};s.jb=function(b){var a,c,e;if(s.Ha()&&b.length>2047&&(typeof XMLHttpRequest!="undefined"&&(a=new XMLHttpRequest,"withCredentials"in a?c=1:a=0),!a&&typeof XDomainRequest!="undefined"&&(a=new XDomainRequest,c=2),a&&s.AudienceManagement&&s.AudienceManagement.isReady()))s.Q?a.ja=!0:a=0;!a&&
  s.ab&&(b=b.substring(0,2047));if(!a&&s.d.createElement&&s.AudienceManagement&&s.AudienceManagement.isReady()&&(a=s.d.createElement("SCRIPT"))&&"async"in a)(e=(e=s.d.getElementsByTagName("HEAD"))&&e[0]?e[0]:s.d.body)?(a.type="text/javascript",a.setAttribute("async","async"),c=3):a=0;if(!a)a=new Image,a.alt="";a.la=function(){try{if(s.ga)clearTimeout(s.ga),s.ga=0;if(a.timeout)clearTimeout(a.timeout),a.timeout=0}catch(b){}};a.onload=a.lb=function(){a.la();s.Ra();s.V();s.v=0;s.Y();if(a.ja){a.ja=!1;try{var b=
    s.P(a.responseText);AudienceManagement.passData(b)}catch(c){}}};a.onabort=a.onerror=a.Ta=function(){a.la();(s.trackOffline||s.ea)&&s.v&&s.e.unshift(s.Qa);s.v=0;s.aa>s.G&&s.xa(s.e);s.V();s.ha(500)};a.onreadystatechange=function(){a.readyState==4&&(a.status==200?a.lb():a.Ta())};s.wa=s.r();if(c==1||c==2){var d=b.indexOf("?");e=b.substring(0,d);d=b.substring(d+1);d=d.replace(/&callback=[a-zA-Z0-9_.\[\]]+/,"");c==1?(a.open("POST",e,!0),a.send(d)):c==2&&(a.open("POST",e),a.send(d))}else if(a.src=b,c==3){if(s.ua)try{e.removeChild(s.ua)}catch(f){}e.firstChild?
    e.insertBefore(a,e.firstChild):e.appendChild(a);s.ua=s.Pa}if(a.abort)s.ga=setTimeout(a.abort,5E3);s.Qa=b;s.Pa=w["s_i_"+s.replace(s.account,",","_")]=a;if(s.useForcedLinkTracking&&s.A||s.p){if(!s.forcedLinkTrackingTimeout)s.forcedLinkTrackingTimeout=250;s.W=setTimeout(s.V,s.forcedLinkTrackingTimeout)}};s.Ra=function(){if(s.fa()&&!(s.va>s.G))try{w.localStorage.removeItem(s.da()),s.va=s.r()}catch(b){}};s.xa=function(b){if(s.fa()){s.za();try{w.localStorage.setItem(s.da(),w.JSON.stringify(b)),s.G=s.r()}catch(a){}}};
  s.za=function(){if(s.trackOffline){if(!s.offlineLimit||s.offlineLimit<=0)s.offlineLimit=10;for(;s.e.length>s.offlineLimit;)s.na()}};s.forceOffline=function(){s.ea=!0};s.forceOnline=function(){s.ea=!1};s.da=function(){return s.offlineFilename+"-"+s.visitorNamespace+s.account};s.r=function(){return(new Date).getTime()};s.ra=function(s){s=s.toLowerCase();if(s.indexOf("#")!=0&&s.indexOf("about:")!=0&&s.indexOf("opera:")!=0&&s.indexOf("javascript:")!=0)return!0;return!1};s.setTagContainer=function(b){var a,
    c,e;s.mb=b;for(a=0;a<s._il.length;a++)if((c=s._il[a])&&c._c=="s_l"&&c.tagContainerName==b){s.K(c);if(c.lmq)for(a=0;a<c.lmq.length;a++)e=c.lmq[a],s.loadModule(e.n);if(c.ml)for(e in c.ml)if(s[e])for(a in b=s[e],e=c.ml[e],e)if(!Object.prototype[a]&&(typeof e[a]!="function"||(""+e[a]).indexOf("s_c_il")<0))b[a]=e[a];if(c.mmq)for(a=0;a<c.mmq.length;a++)e=c.mmq[a],s[e.m]&&(b=s[e.m],b[e.f]&&typeof b[e.f]=="function"&&(e.a?b[e.f].apply(b,e.a):b[e.f].apply(b)));if(c.tq)for(a=0;a<c.tq.length;a++)s.track(c.tq[a]);
    c.s=s;break}};s.Util={urlEncode:s.escape,urlDecode:s.unescape,cookieRead:s.cookieRead,cookieWrite:s.cookieWrite,getQueryParam:function(b,a,c){var e;a||(a=s.pageURL?s.pageURL:w.location);c||(c="&");if(b&&a&&(a=""+a,e=a.indexOf("?"),e>=0&&(a=c+a.substring(e+1)+c,e=a.indexOf(c+b+"="),e>=0&&(a=a.substring(e+c.length+b.length+1),e=a.indexOf(c),e>=0&&(a=a.substring(0,e)),a.length>0))))return s.unescape(a);return""}};s.z=["supplementalDataID","timestamp","dynamicVariablePrefix","visitorID","marketingCloudVisitorID",
    "analyticsVisitorID","audienceManagerLocationHint","authState","fid","vmk","visitorMigrationKey","visitorMigrationServer","visitorMigrationServerSecure","charSet","visitorNamespace","cookieDomainPeriods","fpCookieDomainPeriods","cookieLifetime","pageName","pageURL","referrer","contextData","currencyCode","lightProfileID","lightStoreForSeconds","lightIncrementBy","retrieveLightProfiles","deleteLightProfiles","retrieveLightData","pe","pev1","pev2","pev3","pageURLRest"];s.c=s.z.concat(["purchaseID",
    "variableProvider","channel","server","pageType","transactionID","campaign","state","zip","events","events2","products","audienceManagerBlob","tnt"]);s.ba=["timestamp","charSet","visitorNamespace","cookieDomainPeriods","cookieLifetime","contextData","lightProfileID","lightStoreForSeconds","lightIncrementBy"];s.H=s.ba.slice(0);s.ia=["account","allAccounts","debugTracking","visitor","trackOffline","offlineLimit","offlineThrottleDelay","offlineFilename","usePlugins","doPlugins","configURL","visitorSampling",
    "visitorSamplingGroup","linkObject","clickObject","linkURL","linkName","linkType","trackDownloadLinks","trackExternalLinks","trackClickMap","trackInlineStats","linkLeaveQueryString","linkTrackVars","linkTrackEvents","linkDownloadFileTypes","linkExternalFilters","linkInternalFilters","useForcedLinkTracking","forcedLinkTrackingTimeout","trackingServer","trackingServerSecure","ssl","abort","mobile","dc","lightTrackVars","maxDelay","expectSupplementalData","AudienceManagement"];for(i=0;i<=250;i++)i<76&&
  (s.c.push("prop"+i),s.H.push("prop"+i)),s.c.push("eVar"+i),s.H.push("eVar"+i),i<6&&s.c.push("hier"+i),i<4&&s.c.push("list"+i);i=["latitude","longitude","resolution","colorDepth","javascriptVersion","javaEnabled","cookiesEnabled","browserWidth","browserHeight","connectionType","homepage"];s.c=s.c.concat(i);s.z=s.z.concat(i);s.ssl=w.location.protocol.toLowerCase().indexOf("https")>=0;s.charSet="UTF-8";s.contextData={};s.offlineThrottleDelay=0;s.offlineFilename="AppMeasurement.offline";s.wa=0;s.aa=0;
  s.G=0;s.va=0;s.linkDownloadFileTypes="exe,zip,wav,mp3,mov,mpg,avi,wmv,pdf,doc,docx,xls,xlsx,ppt,pptx";s.w=w;s.d=w.document;try{s.ab=navigator.appName=="Microsoft Internet Explorer"}catch(t){}s.V=function(){if(s.W)w.clearTimeout(s.W),s.W=k;s.i&&s.A&&s.i.dispatchEvent(s.A);if(s.p)if(typeof s.p=="function")s.p();else if(s.i&&s.i.href)s.d.location=s.i.href;s.i=s.A=s.p=0};s.ya=function(){s.b=s.d.body;if(s.b)if(s.o=function(b){var a,c,e,d,f;if(!(s.d&&s.d.getElementById("cppXYctnr")||b&&b["s_fe_"+s._in])){if(s.ka)if(s.useForcedLinkTracking)s.b.removeEventListener("click",
      s.o,!1);else{s.b.removeEventListener("click",s.o,!0);s.ka=s.useForcedLinkTracking=0;return}else s.useForcedLinkTracking=0;s.clickObject=b.srcElement?b.srcElement:b.target;try{if(s.clickObject&&(!s.F||s.F!=s.clickObject)&&(s.clickObject.tagName||s.clickObject.parentElement||s.clickObject.parentNode)){var g=s.F=s.clickObject;if(s.Z)clearTimeout(s.Z),s.Z=0;s.Z=setTimeout(function(){if(s.F==g)s.F=0},1E4);e=s.pa();s.track();if(e<s.pa()&&s.useForcedLinkTracking&&b.target){for(d=b.target;d&&d!=s.b&&d.tagName.toUpperCase()!=
    "A"&&d.tagName.toUpperCase()!="AREA";)d=d.parentNode;if(d&&(f=d.href,s.ra(f)||(f=0),c=d.target,b.target.dispatchEvent&&f&&(!c||c=="_self"||c=="_top"||c=="_parent"||w.name&&c==w.name))){try{a=s.d.createEvent("MouseEvents")}catch(i){a=new w.MouseEvent}if(a){try{a.initMouseEvent("click",b.bubbles,b.cancelable,b.view,b.detail,b.screenX,b.screenY,b.clientX,b.clientY,b.ctrlKey,b.altKey,b.shiftKey,b.metaKey,b.button,b.relatedTarget)}catch(k){a=0}if(a)a["s_fe_"+s._in]=a.s_fe=1,b.stopPropagation(),b.kb&&b.kb(),
      b.preventDefault(),s.i=b.target,s.A=a}}}}else s.clickObject=0}catch(m){s.clickObject=0}}},s.b&&s.b.attachEvent)s.b.attachEvent("onclick",s.o);else{if(s.b&&s.b.addEventListener){if(navigator&&(navigator.userAgent.indexOf("WebKit")>=0&&s.d.createEvent||navigator.userAgent.indexOf("Firefox/2")>=0&&w.MouseEvent))s.ka=1,s.useForcedLinkTracking=1,s.b.addEventListener("click",s.o,!0);s.b.addEventListener("click",s.o,!1)}}else setTimeout(s.ya,30)};s.ya()}
function s_gi(s){var w,k=window.s_c_il,m,i,o=s.split(","),p,n,r=0;if(k)for(m=0;!r&&m<k.length;){w=k[m];if(w._c=="s_c"&&(w.account||w.oun))if(w.account&&w.account==s)r=1;else{i=w.account?w.account:w.oun;i=w.allAccounts?w.allAccounts:i.split(",");for(p=0;p<o.length;p++)for(n=0;n<i.length;n++)o[p]==i[n]&&(r=1)}m++}r||(w=new AppMeasurement);w.setAccount?w.setAccount(s):w.sa&&w.sa(s);return w}AppMeasurement.getInstance=s_gi;window.s_objectID||(window.s_objectID=0);
function s_pgicq(){var s=window,w=s.s_giq,k,m,i;if(w)for(k=0;k<w.length;k++)m=w[k],i=s_gi(m.oun),i.setAccount(m.un),i.setTagContainer(m.tagContainerName);s.s_giq=0}s_pgicq();
