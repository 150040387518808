define('ember-require-module', function() {
  return {
    default: function requireModule(module, exportName) {
      'use strict';
      exportName = exportName || 'default';
      var rjs = requirejs;

      if (
        (rjs.has && rjs.has(module)) ||
        (!rjs.has && (rjs.entries[module] || rjs.entries[`${module}/index`]))
      ) {
        return require(module)[exportName];
      }
    }
  };
});
